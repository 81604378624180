.switch-enter {
  opacity: 0;
}
.switch-enter-active {
  opacity: 1;
  transition: opacity 4000ms;
}

.switch-exit {
  opacity: 0.7;
}
.switch-exit-active {
  opacity: 0.7;
  transition: opacity 4000ms;
}
