.switch-enter {
  opacity: 0;
}
.switch-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.switch-exit {
  opacity: 0.7;
}
.switch-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
